import template from './loader.html';

export default loader;

/** @ngInject */
function loader($http, $rootScope) {
  const directive = {
    restrict: 'A',
    template,
    link: linkFunc,
  };

  return directive;

  function linkFunc(scope, elem) {
    const blacklist = ['api/campaign'];
    scope.isLoading = () => {
      let count = $http.pendingRequests.length;

      // We reduce the count by 1 for every request in the pendingRequests array that has
      // at least one blacklisted string as a substring
      $http.pendingRequests.forEach(checkIfBlacklisted);

      return count > 0;

      function checkIfBlacklisted(request) {
        if (blacklist.some(requestContainsBlacklistString)) {
          count -= 1;
        }

        function requestContainsBlacklistString(blacklistString) {
          return request.url.indexOf(blacklistString) > -1;
        }
      }
    };

    scope.$watch(scope.isLoading, (value) => {
      if (value) {
        elem.show();
      } else {
        elem.hide();
      }
    });

    $rootScope.showLoader = () => {
      elem.show();
    };

    $rootScope.hideLoader = () => {
      elem.hide();
    };
  }
}
