import template from './aboutUs.html';

export default aboutUs;

function aboutUs() {
  const directive = {
    restrict: 'E',
    controller: AboutUs,
    controllerAs: 'vmAboutUs',
    bindToController: true,
    template,
  };

  return directive;
}

/** @ngInject */
function AboutUs($state) {
  const vmAboutUs = this;
  if ($state.current.data) { vmAboutUs.pageTitle = $state.current.data.pageTitle; }
}
