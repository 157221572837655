import angular from 'angular';
import template from './main.html';

export default homeIndex;

function homeIndex() {
  return {
    restrict: 'E',
    controller: angular.noop,
    controllerAs: 'vmHomeIndex',
    bindToController: true,
    template,
  };
}
