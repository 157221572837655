export default heapService;

/** @ngInject */
function heapService($log) {
  const heap = window.heap;

  return {
    setUserIdentity,
    setUserInformation,
  };

  function setUserIdentity(data) {
    try {
      heap.identify(data);
    } catch (err) {
      $log.log(err);
    }
  }

  function setUserInformation(data) {
    try {
      const userVars = {};
      angular.extend(userVars, data);

      if (data.opportunityId || data.contactId) {
        heap.addUserProperties(userVars);
      }
    } catch (err) {
      $log.log(err);
    }
  }
}
