import template from './topHeaderBar.html';

export default topHeaderBar;

function topHeaderBar() {
  const directive = {
    restrict: 'E',
    template,
  };

  return directive;
}
