/**
 * Construct the version information for this build.
 *
 * Constants are injected by a webpack build.
 *
 * @type {{major: *, minor: *, patch: *, build: *, year: number}}
 */

module.exports = {
  // eslint-disable-next-line no-undef
  major: VERSION_MAJOR,

  // eslint-disable-next-line no-undef
  minor: VERSION_MINOR,

  // eslint-disable-next-line no-undef
  patch: VERSION_PATCH,

  // eslint-disable-next-line no-undef
  build: VERSION_BUILD,

  // eslint-disable-next-line no-undef
  year: CONFIG_YEAR
};
