export default config;

/** @ngInject */
function config(
  $httpProvider,
  $locationProvider,
  $logProvider,
  $sessionStorageProvider,
  $urlRouterProvider,
) {
  // Enable log
  $logProvider.debugEnabled(true);
  $locationProvider.html5Mode(true);

  // Watches for statuses indicating that the site is in Maintenance mode;
  // if so, forces a page refresh, which should display the maintenance page.
  $httpProvider.interceptors.push(($q, $window) => ({
    responseError(rejection) {
      if (rejection.status === 503 && $window.location.pathname !== '/maintenance') {
        $sessionStorageProvider.set('maintenanceSource', $window.location.pathname);
        $window.location = '/maintenance';
      }

      return $q.reject(rejection);
    },
  }));

  $urlRouterProvider.rule(($injector, $location) => {
    const path = $location.path();
    const fullpath = $location.absUrl();
    const queryLocation = fullpath.indexOf('?');

    let query;
    if (queryLocation > 0) {
      query = fullpath.substring(queryLocation);
    }

    const hasTrailingSlash = path.substr(-1) === '/';

    if (hasTrailingSlash) {
      return undefined;
    }

    // Since there's no trailing slash, we need to insert one between the
    // path and the query string (if a query string exists)
    return query ? `${path}/${query}` : `${path}/`;
  });

  $urlRouterProvider.rule(($injector, $location) => {
    const path = $location.path();
    const normalized = path.toLowerCase();
    if (path !== normalized) {
      $location.replace().path(normalized);
    } else if (normalized.indexOf('/intuit') === 0 && normalized.indexOf('/intuitem?') !== 0
        && normalized.indexOf('/intuitdm?') !== 0) {
      $location.url('/intuit');
    } else {
      $location.path('/not-found');
    }
  });
}
