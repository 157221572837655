export default routerConfig;

/** @ngInject */
function routerConfig($stateProvider) {
  $stateProvider
    .state('aboutUs', {
      url: '/about-us/',
      onEnter($window) {
        $window.open('https://www.loanbuilder.com', '_self');
      },
    });
}
