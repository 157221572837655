import template from './header.html';

export default header;

function header() {
  const directive = {
    restrict: 'E',
    link() {
      angular.element('.hamburger-wrap').bind('click', () => {
        angular.element('.hamburger').toggleClass('x');
        angular.element('.nav-links, .top-nav, .top-nav .inside').toggleClass('active');
      });

      angular.element('.nav-links a, .logo-top').bind('click', () => {
        angular.element('.hamburger').removeClass('x');
        angular.element('.nav-links, .top-nav, .top-nav.inside').removeClass('active');
      });
    },
    template,
  };

  return directive;
}
