import angular from 'angular';

import 'angular-modal-service';
import 'angular-ui-router';
import 'ngstorage';
import 'angular-animate';
import 'angular-sanitize';
import 'angular-mocks';
import 'angular-update-meta';
import 'jquery-match-height';
import 'jquery-mask-plugin';
import 'ngmap';

import routesConfig from './index.route';
import indexConfig from './index.config';
import run from './index.run';

import main from './app/main';
import aboutUsModule from './app/aboutUs';

// Webpack-generated modules
import config from './config/config';
import versionConfig from './config/version';

import './index.scss';

const app = 'app';
export default app;

angular
  .module(app, [
    'angularModalService',
    'ngAnimate',
    'ngMap',
    'ngMockE2E',
    'ngSanitize',
    'ngStorage',
    'ui.router',
    'updateMeta',
    aboutUsModule,
  ])
  .directive('sfnHomeIndex', main)
  .run(run)
  .config(routesConfig)
  .config(indexConfig)
  .constant('EnvironmentConfig', config)
  .constant('version', versionConfig);
