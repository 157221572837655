import angular from 'angular';

import ctaDark from './ctaDark/cta-dark.directive';
import footer from './footer/footer.directive';
import header from './header/header.directive';
import heap from './heap/heap.service';
import loader from './loader/loader.directive';
import topHeaderBar from './topHeaderBar/topHeaderBar.directive';

const moduleName = 'components';

export default moduleName;

angular
  .module(moduleName, [])
  .component('sfnFooter', footer)
  .directive('sfnCtaDark', ctaDark)
  .directive('sfnHeader', header)
  .directive('sfnLoader', loader)
  .directive('sfnTopHeaderBar', topHeaderBar)
  .service('heapService', heap);
