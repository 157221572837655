export default routerConfig;

/** @ngInject */
function routerConfig($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('home', {
      url: '/',
      onEnter($window) {
        $window.open('https://www.loanbuilder.com', '_self');
      },
    });
}
