export default runBlock;

/** @ngInject */
function runBlock($httpBackend, $location, $log, $rootScope, $sessionStorage, $timeout, $window) {
  $httpBackend.whenGET(/\.html$/).passThrough();

  // Passthrough everything
  $httpBackend.whenGET(/[\s\S]*/).passThrough();

  $rootScope.$on('$stateChangeStart', () => {
    $window.open('https://www.loanbuilder.com', '_self');
  });
  $rootScope.$on('$stateChangeSuccess', () => {
    const absUrl = $location.absUrl();
    if (!$sessionStorage.leadSourceUrl) {
      $sessionStorage.leadSourceUrl = absUrl;
    }

    if (!$window.ga) {
      return;
    }
    $timeout(() => {
      $window.ga('send', 'pageview', $location.path());
    }, 0);
  });

  $log.debug('runBlock end');
}
