import template from './footer.html';

/** @ngInject */
function controller(version) {
  const vm = this;

  vm.version = `v${version.major}.${version.minor}.${version.patch}.${version.build}`;
}

export default {
  bindings: {
    premiumOffer: '=',
  },
  controller,
  controllerAs: 'vmFooter',
  template,
};
