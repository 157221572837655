import angular from 'angular';
import componentsModule from '../components/index';
import aboutUsDirective from './aboutUs.directive';
import aboutUsRoutes from './aboutUs.route';

const moduleName = 'aboutUs';

export default moduleName;

angular
  .module(moduleName, [
    componentsModule,
  ])
  .config(aboutUsRoutes)
  .directive('sfnAboutUs', aboutUsDirective);
